.html-view {
  box-sizing: border-box;
  width: 1000px;
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 30px 20px;
  overflow: hidden;
  background-color: #fff;
  border-right: solid 1px #eee;
  border-left: solid 1px #eee;
}
.html-view img,
.html-view audio,
.html-view video {
  max-width: 100%;
  height: auto;
}
.html-view p {
  white-space: pre-wrap;
  min-height: 1em;
}
.html-view pre {
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 5px;
}
.html-view blockquote {
  margin: 0;
  padding: 15px;
  background-color: #f1f1f1;
  border-left: 3px solid #d1d1d1;
}

iframe,
object,
embed {
  max-width: 100%;
  max-height: 100%;
}
