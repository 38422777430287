.site-layout-content {
  min-height: 85vh;
  padding: 24px;
  background: #fff;
}

.logo {
  color: #fff;
  font-weight: 600;
  font-size: large;
  float: left;
  width: 120px;
}

.fr-container .fr-label-title {
  display: block !important;
}
